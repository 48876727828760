import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import CMSPreviewStyles from '../styles/CMS.module.sass'

/**
 * Display a dynamic page template
 *
 * @param {string} templateKey
 * @param {string} title
 * @param {string} subtitle
 * @param {string} pageClass
 * @param {object} image
 * @param {boolean} showCTA Default: true | Toggles CTA button in Hero
 */
const PageTemplate = ({
  title,
  h2Title,
  pageIdentifier,
  seoTitle,
  seoDescription,
  pageClass,
  subtitle,
  image,
  showCTA,
  body,
  disableMdx,
  imageAnchor,
  imageAlt,
}) => {
  return (
    <Layout
      className={`is-page-${pageIdentifier} ${pageClass ? pageClass : ''}`}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
    >
      <Hero
        title={title}
        subtitle={subtitle}
        image={image}
        showCTA={showCTA}
        h2Title={h2Title}
        anchor={imageAnchor}
        imageAlt={imageAlt || title}
      />
      <div className="container content content-body is-narrow">
        {/* Markdown fallback for CMS Previews */}
        {disableMdx === true || disableMdx === 'true' ? (
          body
        ) : (
          <MDXRenderer>{body}</MDXRenderer>
        )}
      </div>
    </Layout>
  )
}

export { PageTemplate }

/**
 * Create Netlify CMS-compatible Preview Component
 */
const Page = ({ data }) => {
  // Split imageAnchor classes when multiple
  const formattedImageAnchor = data.mdx.frontmatter.imageAnchor
    ? data.mdx.frontmatter.imageAnchor.toString().replace(',', ' ')
    : ''

  return (
    <PageTemplate
      title={data.mdx.frontmatter.title}
      h2Title={data.mdx.frontmatter.h2Title}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      seoTitle={data.mdx.frontmatter.seoTitle}
      seoDescription={data.mdx.frontmatter.seoDescription}
      pageClass={data.mdx.frontmatter.pageClass}
      subtitle={data.mdx.frontmatter.subtitle}
      image={data.mdx.fields.image}
      imageAlt={data.mdx.frontmatter.imageAlt}
      showCTA={data.mdx.frontmatter.showCTA}
      body={data.mdx.body}
      imageAnchor={formattedImageAnchor || ''}
    />
  )
}

export default Page

/**
 * Configure Netlify CMS Editor Preview
 */
const PagePreview = ({ entry, widgetFor }) => {
  const data = entry.getIn(['data']).toJS()

  if (data) {
    // Split imageAnchor classes when multiple
    const formattedImageAnchor = data.imageAnchor
      ? data.imageAnchor.toString().replace(',', ' ')
      : ''

    return (
      <PageTemplate
        title={data.title}
        pageIdentifier={`${data.slug || 'page'} ${CMSPreviewStyles.cms}`}
        seoTitle=""
        seoDescription=""
        pageClass={data.pageClass}
        subtitle={data.subtitle}
        image={data.image}
        imageAnchor={formattedImageAnchor || ''}
        showCTA={data.showCTA}
        body={widgetFor('body')}
        disableMdx="true"
      />
    )
  } else {
    return <div>Loading...</div>
  }
}

export { PagePreview }

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        h2Title
        pageClass
        templateKey
        showCTA
        subtitle
        seoTitle
        seoDescription
        imageAnchor
        imageAlt
      }
      fields {
        pageIdentifier
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
